<template>
  <div
    class="mr-50 cursor-pointer"
    @click="onClick"
  >
    <feather-icon
      size="20"
      icon="GridIcon"
      style="margin-top: -3px;"
    />
    <span class="ml-50 text-body">{{ lineOa.name }}</span>
  </div>
</template>

<script>
import store from '@/store'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    FeatherIcon,
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '') {
        return value
      }
      return JSON.parse(value)
    },
    currentRouteName() {
      return this.$route.name
    },
  },
  mounted() {
    if (this.lineOa === '') {
      this.$router.push({ name: 'line-oa' })
    }
  },
  methods: {
    onClick() {
      this.$router.push({ name: 'line-oa', params: { prevRoute: this.currentRouteName } })
    },
  },
}
</script>

<style>

</style>
