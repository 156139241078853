<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        :href="appUrl"
        target="_blank"
      >{{ appCompany }}</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block">Power by
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-primary stroke-current"
      />
      <span style="margin-left: 0.6em;">{{ appVersion }}</span>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
  },
  setup() {
    // App Name
    const { appCompany, appUrl, appVersion } = $themeConfig.app
    return {
      appCompany,
      appUrl,
      appVersion,
    }
  },
}
</script>
