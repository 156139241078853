<template>
  <b-nav-item @click="layoutType = isVertical ? 'horizontal' : 'vertical'">
    <feather-icon
      size="21"
      :icon="`${isVertical ? 'CreditCard' : 'Sidebar'}Icon`"
    />
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
  },
  setup() {
    const { layoutType } = useAppConfig()

    const isVertical = computed(() => layoutType.value === 'vertical')

    return { layoutType, isVertical }
  },
}
</script>

<style>

</style>
