// Array of sections
import home from './home'
import center from './center'
import lineapi from './lineapi'

// Array of sections
export default [
  ...home,
  ...center,
  ...lineapi,
]
