export default [
  {
    header: 'Center',
    action: 'default',
  },
  {
    title: 'User Account',
    icon: 'UserIcon',
    route: 'user-account',
    action: 'default',
  },
  {
    header: 'Management',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'User',
    route: 'user-list',
    icon: 'UsersIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'User Groups',
    icon: 'UserIcon',
    route: 'user-group',
    resource: 'UserGroup',
    action: 'read',
  },
]
